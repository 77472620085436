export const Style = {
  main: (isDashboard, check) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: { xs: "center", sm: check ? "center" : "space-between" },
    // flexDirection: { xs: "column", md: "row" },
    flexDirection: { xs: "row" },
    gap: "16px",
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: {xs: isDashboard ? '1100px' : "1320px", xl: '1470px'},
    mx: 'auto',
    flexWrap: 'wrap'
  }),
  headingContainer: (isDrawer) => ({
    color: "#2E1B3ACC",
    display: "flex",
    alignItems: "center",
    alignSelf: { xs: "start", md: "center" },
    width: { xs: "100%", sm: "fit-content", md: "fit-content", xl: 'calc(100% - 600px)' },
    maxWidth: {xs: 'calc(100vw - 20px)', sm: !isDrawer ? "calc(100vw - 130px)" : "calc(100vw - 328px)"},
    scrollbarWidth: "thin",
    gap: "16px",
  }),
  menuIconContainer: { xs: "block", sm: "none" },
  menuIcon: { fontSize: {xs: "26px", sm: "36px"} },
  icon: { height: { xs: 40, md: 60 }, width: { xs: 50, md: 80 } },
  pageIconContainer: {
    display: "flex",
    width: "100%",
    width: {xs: "calc(100% - 40px)", sm: '100%'},
    gap: { xs: "6px", md: "18px" },
    alignSelf: { xs: "start", md: "center" },
  },
  role: {
    color: "#787486",
    textAlign: "right",
    fontFamily: "Rubik",
    fontSize: { xs: "14px", xl: "16px" },
    fontWeight: "400",
    lineHeight: "normal",
  },
  name: {
    color: "#3447D4",
    textAlign: "right",
    fontFamily: "Rubik",
    fontSize: { xs: "16px", xl: "20px" },
    fontWeight: "500",
  },
  divider: { borderWidth: "1.5px", borderColor: "#2E1B3ACC" },
  optionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    maxWidth: {xs: '430px', xl: '550px'},
    ml: 'auto',
    width: "100%",
  },
  searchWrapper: {
    p: "2px 10px 2px 4px",
    display: "flex",
    alignItems: "center",
    width: { xs: "100%" },
    minWidth: { xs: "100px", md: "150px" },
    maxWidth: { xs: "250px", xl: "275px" },
    borderRadius: "8px !important",
    boxShadow: "none",
    border: "1px solid #D7DBE7",
    height: { xs: "40px", xl: "45px" },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "0px",
    ml: { xs: "0", md: "20px" },
    position: "relative",
  },
  popOverButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  headerIcon: {
    width: { xs: 40, sm: 48 },
    height: { xs: 40, sm: 48 },
    ml: { xs: 0, sm: 1, md: 2 },
    color: "#3346D3",
  },
  grayBox: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: "113",
  },
  menu: {
    position: "absolute",
    top: "60px",
    right: 0,
    width: "230px",
    borderRadius: "12px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    py: 3,
    zIndex: 114,
    display: "flex",
    flexDirection: "column",
  },
  menuList: {
    px: 3,
    py: "6px",
    color: "#1F1F1F",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400",
    cursor: "pointer",
    opacity: "0.7",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  userNameContainer: { display: "flex", alignItems: "center", gap: "8px" },
  editIcon: {
    cursor: "pointer",
    fontSize: {xs: "28px", xl: '32px'},
    display: "flex",
  },
  text: {
    wordWrap: "break-word !important",
    width: "200px",
    fontSize: { xs: "16px", sm: "20px" },
    boxSizing: "border-box",
    display: "flex",
  },
  ellipse: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    maxWidth:  {xs: '120px', md: "190px"},
  },
};
