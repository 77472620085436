
export const Style = {
  wrapper: {
  maxWidth:  "819px",
    height: { xs: "800px", md: "554px" },
    maxHeight: "96vh",
    overflowY: "auto",
    borderRadius: "0px",
    p: 0,
    textAlign: "start",
    gap: "0px",
    borderRadius: "14px",
  },
};
