export const Style = {
  button: {
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize",
    height: {xs: "40px", xl: '45px'},
    borderRadius: '4px',
    background: '#3447D4',
    gap: '4px',
    border: '1px solid #3447D4',
    boxShadow: "none !important",
    "&:disabled": {
      border: 'none !important'
    }
  },
  buttonText: {
    fontFamily: 'Rubik',
    fontSize: {xs: '16px', xl: '18px'},
    fontWeight: '500',
    lineHeight: '24px',
  }
};
