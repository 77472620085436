import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Input, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleError } from "../../hooks/globalFunction";
import { updateDepartments } from "../../services/departments";
import { selectUser, updateUserData } from "../../store/slice/user";
import { IMAGES } from "../../theme";
import ViewUsers from "../Modals/ViewUsers";
import { Style } from "./style";
import { FiUsers } from "react-icons/fi";
import CustomMenu from "../CustomMenu";
import ProgressBar from "../progressBar";
import CustomButton from "../CustomButton";
import AddIcon from "@mui/icons-material/Add";
import useWindowDimensions from "../../hooks/windowDimensions";
import { selectdrawer } from "../../store/slice/drawer";


const PreviewCards = ({
  isDownload = true,
  data = {},
  handleClick = () => { },
  deleteIconClick = () => { },
  editIconClick = () => { },
  isEdit,
  isDelete,
  editItem,
  setEditItem,
  index,
  isProgressBar,
  assignClickHandling
}) => {
  const { departId, docId } = useParams();
  const location = useLocation()
  const path = location?.pathname?.split("/")[1]
  const { heading, paragraph, date, name, users, is_quizzes } = data;
  const [inputVal, setInputVal] = useState(editItem?.item?.name);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { access, departments, isAdmin, isUser, isSuperAdmin } = useSelector(selectUser);
  const dispatch = useDispatch();
  const defaultDepartment = useMemo(() => {
    return IMAGES.department;
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation()
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const defaultDocument = useMemo(() => {
    return IMAGES.document;
  }, []);


  useEffect(() => {
    setInputVal(editItem?.item?.name);
  }, [editItem]);

  const updateDepartmentName = async () => {
    try {
      setLoader(true);
      const { data } = await updateDepartments(
        dispatch,
        navigate,
        { name: inputVal },
        editItem?.item?.id,
        access
      );
      let tempData = [...departments?.results];
      tempData[editItem?.index] = data;
      dispatch(updateUserData({ departments: { results: tempData } }));
      if (data?.id) {
        navigate("/trainings/");
      }
      toast.success("Department updated successfully");
      setLoader(false);
      setEditItem(false);
    } catch (err) {
      toast.error(handleError({ name: inputVal }, err?.response?.data));
      setLoader(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    updateDepartmentName();
  };

  const handleDownload = (e) => {
    e.stopPropagation()
    window.open(
      typeof data?.file == "string"
        ? data?.file
        : `https://app.traino.ai/media/documents/` + data?.name
    );
  };
  const condition = editItem?.index === index && !!editItem

  const assigned_data = data?.assigned_users_details
  const { width } = useWindowDimensions();
  const { drawer } = useSelector(selectdrawer);


  return (
    <>
      <Card sx={Style.main(width, drawer)} onClick={handleClick}>
        {date && (
          <Typography sx={Style.uploadDate}>Uploaded date : {date}</Typography>
        )}
        <Box sx={Style.contentContainer}>
          <Box sx={Style.content(path === "trainings" || isUser)}>
            <CardMedia
              component={"img"}
              sx={{
                height: {xs: (width < 420 || (drawer ? width > 825 : width > 680)) ? ((path === "trainings" || isUser) ?  "156px" : "144px") : "200px", xl: (path === "trainings" || isUser) ?  "180px" : '160px'},
                background: "none",
                objectFit: 'cover',
              }}
              src={(path === "trainings" || isUser) ? defaultDocument : defaultDepartment}
            />
          </Box>
          <CardContent
            style={Style.contentBox(path === "trainings" || isUser)}
          >
            <form id={condition && "no_change"} onSubmit={submit} style={Style.title(path === "trainings" || isUser)} onClick={(e) => condition && e.stopPropagation()}>
              <Box sx={Style.departContentBox(isAdmin && !departId)}>
                <Tooltip title={heading || name}>
                  <Typography
                    onClick={handleClick}
                    sx={{ ...Style.pointerCusrsor, ...Style.heading(path === "trainings" || !isAdmin) }}
                  >
                    {heading || name}
                  </Typography>
                </Tooltip>
              </Box>
              {!departId &&
                <>
                  {isAdmin &&
                    <CustomMenu itemSx={{ display: 'flex', gap: 1.5 }} options={[
                      { icon: <Box component={"img"} src={IMAGES.edit} sx={{ ...Style.buttonIcon, ...Style.pointerCusrsor }} />, option: 'Edit', handleClick: editIconClick },
                      { icon: <Box component={"img"} src={IMAGES.delete} sx={{ ...Style.buttonIcon, ...Style.pointerCusrsor }} />, option: 'Delete', handleClick: deleteIconClick }
                    ]} />}
                </>
              }
            </form>
            <Box sx={{ m: (path === "trainings" || isUser) && '16px 0 8px' }}>
              {!isUser && path === "my-teams" &&
                <Box sx={Style.viewBox}>
                  <FiUsers style={Style.userIcon} />
                  <Typography style={Style.userNo}>{users?.length} {users?.length > 1 ? 'Members' : 'Member'}</Typography>
                </Box>
              }
              {(path === "trainings" || isUser) &&
                <>
                  <Typography sx={Style.spaceBetween}>
                    <Typography component={"span"} sx={Style.bold}>Number of Quizzes </Typography>
                    <Typography sx={Style.quizNo} component={"span"} >{`${data?.quizzes <= 9 ? "0" : ""}${data?.quizzes || 0}`}</Typography>
                  </Typography>
                  <Typography sx={{ ...Style.spaceBetween, mt: "10px", mb: 2 }}>
                    <Typography component={"span"} sx={Style.bold}>Number of Trainees </Typography>
                    <Typography component={"a"} onClick={handleOpen} sx={{ ...Style.quizNo, textDecoration: 'underline' }}>
                      {`${data?.assigned_users?.length <= 9 ? "0" : ""}${data?.assigned_users?.length || 0}`}
                    </Typography>
                  </Typography>
                  {isProgressBar &&
                    <Box sx={{ ...Style.spaceBetween, mt: 1.5 }}>
                      <ProgressBar dots={width > 900 ? 11 : width > 600 ? 10 : 9} />
                      <Typography sx={Style.progressRatio}>02/04</Typography>
                    </Box>
                  }
                </>
              }
            </Box>
          </CardContent>
          {
            isDownload &&
            <CardActions sx={{
              ...Style.center, p: 0
            }}>
              {!isAdmin ? <Box component={"img"} src={IMAGES.download} sx={Style.downloadIcon}
                onClick={handleDownload} /> :
                <CustomButton onClick={(e) => {
                  e.stopPropagation()
                  assignClickHandling()
                }} disable={!is_quizzes} sx={{ ...Style.downloadIcon, ...Style.assignButton }} buttonText={"Assign"} icon={<AddIcon />} />
              }
            </CardActions>
          }
        </Box>
      </Card>
      <ViewUsers open={open} setOpen={setOpen} data={assigned_data} />
    </>
  );
};

export default PreviewCards;
